/** @format */

import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

export default function AbroadBrokerRegisterNavigationButton({
  onClickHandler,
  to = '/abroad',
  id
}) {
  return (
    <div id={id} className='abroad-broker-header-button-wrapper'>
      <Link to={to}>
        <Button
          onClick={onClickHandler}
          className={`btn btn-white btn-primary broker-contact-header-button`}>
          Abroad
        </Button>
      </Link>
    </div>
  );
}
