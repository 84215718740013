/** @format */

import React from 'react';
import config from 'config';
import { Switch, Route } from 'react-router-dom';
import RoutedComponent from 'components/RoutedComponent';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
/** Containers sorted according to the registration flow order */

/**
 * Registration views
 */

import HomePage from 'views/Registration/HomePage';
import Register from 'views/Registration/Register';
import Welcome from 'views/Onboarding/Welcome';
import FMHub from 'views/Onboarding/FMHub';
import Agreement from 'views/Onboarding/Agreement';
import SignUp from 'views/Onboarding/SignUp';
import ContactInfo from 'views/Onboarding/Onboarding/ContactInfo';

/**
 * Common views
 */
import PageNotFound from 'views/Static/PageNotFound';
import BrokerProfile from 'views/Common/BrokerProfile';
import ObjectListing from 'views/Common/ObjectListing';
import ViewImages from 'views/Common/ViewImages';
import About from 'views/Common/About';
import FAQ from 'views/Common/FAQ';
import Contact from 'views/Common/Contact';
import ArticlePage from 'views/Common/ArticlePage';
import ArticlePage2 from 'views/Common/ArticlePage2';
import ArticlePage3 from 'views/Common/ArticlePage3';

/**
 * Onboarding views
 */
import BillingAddress from 'views/Onboarding/Onboarding/BillingAddress';
import ActiveAreas from 'views/Onboarding/Onboarding/ActiveAreas';
import ProfilePicture from 'views/Onboarding/Onboarding/ProfilePicture';
import Presentation from 'views/Onboarding/Onboarding/Presentation';
import USP from 'views/Onboarding/Onboarding/USP';
import YourServices from 'views/Onboarding/Onboarding/MyServices';

/**
 * Salary Calculator view
 */
import SalaryCalculator from 'views/Registration/SalaryCalculatorPage';
/**
 * Leads Generator view
 */
import { LeadsGeneratorPage } from 'components/LeadsGenerator';

const {
  constants: { flows, views, flowOrigins }
} = config;

const commonProps = {
  flow: flows.registration,
  flowOrigin: flowOrigins.registration
};

export default function Routes() {
  const { t } = useTranslation();
  const seoCommonTitle = t('lang_common:seo.title.common.text');
  const seoDescription = t('lang_common:seo.title.common.description.text');

  return (
    <>
      <Helmet>
        <title>{seoCommonTitle} </title>
        <link rel='canonical' href={window.location.origin} />
        <meta name='description' content={seoDescription} />
      </Helmet>
      <Switch>
        {/*
         * Beginning of registration flow
         */}
        <RoutedComponent /*** HomePage ***/
          exact={true}
          isPrivateRoute={false}
          Component={HomePage}
          view={views.homepage}
          path={t(`lang_route:registration_homepage`)}
          {...commonProps}
        />

        <RoutedComponent /*** Register page ***/
          exact={true}
          isPrivateRoute={false}
          Component={Register}
          view={views.register}
          path={t(`lang_route:registration_register`)}
          {...commonProps}
        />

        <RoutedComponent /*** Salary Calculator page ***/
          exact={true}
          isPrivateRoute={false}
          Component={SalaryCalculator}
          view={views.homepage}
          path={t(`lang_route:registration_salarycalculator`)}
          {...commonProps}
        />

        <RoutedComponent /*** Leads generator page ***/
          exact={true}
          isPrivateRoute={false}
          Component={LeadsGeneratorPage}
          view={views.homepage}
          path={t(`lang_route:registration_leads_page`)}
          {...commonProps}
        />

        <RoutedComponent /*** Abroad brokers register page ***/
          exact={true}
          isPrivateRoute={false}
          Component={Register}
          view={views.register}
          path={t(`lang_route:registration_abroad_brokers_page`)}
          abroadBrokers={true}
          {...commonProps}
        />

        {/*
         * END of registration flow
         */}
        {/*
         * Beginning of onboarding flow
         */}
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={Welcome}
          view={views.welcome}
          path={t(`lang_route:onboarding_welcome`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={FMHub}
          view={views.fmhub}
          path={t(`lang_route:onboarding_fmhub`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={Agreement}
          view={views.agreement}
          path={t(`lang_route:onboarding_agreement`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={SignUp}
          view={views.signup}
          path={t(`lang_route:onboarding_signup`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={ContactInfo}
          view={views.contactinfo}
          path={t(`lang_route:onboarding_contactinfo`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={BillingAddress}
          view={views.billingaddress}
          path={t(`lang_route:onboarding_billingaddress`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={ActiveAreas}
          view={views.activeareas}
          path={t(`lang_route:onboarding_activeareas`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={ProfilePicture}
          view={views.profilepicture}
          path={t(`lang_route:onboarding_profilepicture`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={Presentation}
          view={views.presentation}
          path={t(`lang_route:onboarding_presentation`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={USP}
          view={views.usp}
          path={t(`lang_route:onboarding_usp`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        <RoutedComponent
          exact={true}
          isPrivateRoute={false}
          Component={YourServices}
          view={views.yourservices}
          path={t(`lang_route:onboarding_yourservices`)}
          flow={flows.onboarding}
          flowOrigin={flowOrigins.onboarding}
        />
        {/*
         * END of onboarding flow
         */}
        {/**
         * common flows
         */}
        <RoutedComponent /*** Contact page ***/
          exact={true}
          isPrivateRoute={false}
          Component={Contact}
          view={views.contact}
          path={t(`lang_route:registration_contact`)}
          flow={flows.common}
          flowOrigin={flowOrigins.common}
        />
        <RoutedComponent /*** view broker profile page ***/
          exact={false}
          isPrivateRoute={false}
          Component={BrokerProfile}
          view={views.broker_profile}
          path={t(`lang_route:common_broker_profile`)}
          flow={flows.common}
          flowOrigin={flowOrigins.common}
        />
        <RoutedComponent /*** view object listings page ***/
          exact={false}
          isPrivateRoute={false}
          Component={ObjectListing}
          view={views.object_listing}
          path={t(`lang_route:common_object_listing`) + '/:vitecObjectId?'}
          flow={flows.common}
          flowOrigin={flowOrigins.common}
        />
        <RoutedComponent /*** view object images page ***/
          exact={false}
          isPrivateRoute={false}
          Component={ViewImages}
          view={views.object_view_images}
          path={t(`lang_route:common_object_view_images`)}
          flow={flows.common}
          flowOrigin={flowOrigins.common}
        />
        <RoutedComponent /*** About page ***/
          exact={true}
          isPrivateRoute={false}
          Component={About}
          view={views.about}
          path={t(`lang_route:registration_about`)}
          flow={flows.common}
          flowOrigin={flowOrigins.common}
        />
        <RoutedComponent /*** Article page ***/
          exact={true}
          isPrivateRoute={false}
          Component={ArticlePage}
          view={views.article}
          path={t(`lang_route:registration_article`)}
          flow={flows.common}
          flowOrigin={flowOrigins.common}
        />
        <RoutedComponent /*** Article page 2s ***/
          exact={true}
          isPrivateRoute={false}
          Component={ArticlePage2}
          view={views.article2}
          path={t(`lang_route:registration_article2`)}
          flow={flows.common}
          flowOrigin={flowOrigins.common}
        />
        <RoutedComponent /*** Article page 2s ***/
          exact={true}
          isPrivateRoute={false}
          Component={ArticlePage3}
          view={views.article3}
          path={t(`lang_route:registration_article3`)}
          flow={flows.common}
          flowOrigin={flowOrigins.common}
        />
        <RoutedComponent /*** FAQ page ***/
          exact={true}
          isPrivateRoute={false}
          Component={FAQ}
          view={views.faq}
          path={t(`lang_route:registration_faq`)}
          flow={flows.common}
          flowOrigin={flowOrigins.common}
        />

        <Route
          path={`${t(`lang_route:registration_homepage`)}404`}
          exact={true}>
          <PageNotFound flow={flows.common} view={views.page_not_found} />
        </Route>

        <RoutedComponent /*** view broker profile page ***/
          exact={true}
          isPrivateRoute={false}
          Component={BrokerProfile}
          view={views.broker_profile}
          path={`${t(`lang_route:registration_homepage`)}:profileSlug`}
          flow={flows.common}
          flowOrigin={flowOrigins.common}
        />

        <Route>
          <PageNotFound flow={flows.common} view={views.page_not_found} />
        </Route>
      </Switch>
    </>
  );
}
