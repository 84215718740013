/** @format */

import React from 'react';
import config from 'config';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FindBrokerSearchBar } from 'components/FindBroker';
import ContactSellerButton from 'components/Buttons/ContactSeller';
import AbroadBrokerNavigationButton from 'components/Buttons/AbroadBrokerRegisterNav';

export default function RegularHeader(props) {
  const {
    buttonText,
    homepageUrl,
    getButtonColor,
    showContactSellerButton,
    setDisplayContactSeller
  } = props;
  const { brokerLoginUrl } = config.app;

  return (
    <>
      <div className='header'>
        <div className='header-left-container'>
          <Link to={homepageUrl} className='logo'>
            <div className='logo-fria-maklare-dark' />
          </Link>
        </div>
        <div className='header-middle-container'>
          {showContactSellerButton ? (
            <ContactSellerButton onClickHandler={setDisplayContactSeller} />
          ) : (
            <FindBrokerSearchBar />
          )}

          <AbroadBrokerNavigationButton id='abroad-broker-desktop-nav-button' />
        </div>
        <div className='header-right-container'>
          {buttonText.map((item, key) => (
            <div className={`header-button-item`} key={key}>
              {key === 0 && (
                <a href={brokerLoginUrl}>
                  <Button className={`btn btn-${getButtonColor(key)}`}>
                    {item.text}
                  </Button>
                </a>
              )}
              {key === 1 && (
                <Link to={item.props.url}>
                  <Button className={`btn btn-${getButtonColor(key)}`}>
                    {item.text}
                  </Button>
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
