/** @format */

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import config from 'config';
import AbroadBrokerNavigationButton from 'components/Buttons/AbroadBrokerRegisterNav';

export default function MobileHeader(props) {
  const { show, setShow, buttonText, getButtonColor } = props;
  const { brokerLoginUrl } = config.app;
  const mobileHeaderOverlayRef = useRef(null);

  useEffect(() => {
    const clickEvent = (event) => {
      if (
        mobileHeaderOverlayRef.current &&
        mobileHeaderOverlayRef.current.contains(event.target)
      ) {
        if (event.target.tagName === 'BUTTON') {
          setShow(false);
        }
      }
    };

    document.addEventListener('click', clickEvent);

    return () => {
      document.removeEventListener('click', clickEvent);
    };
  }, []);

  return (
    <>
      <div
        className={`hamburger-menu-floating-button ${
          show
            ? 'hamburger-menu-floating-button-close'
            : 'hamburger-menu-floating-button-open'
        }`}
        onClick={() => setShow(!show)}></div>
      {show && (
        <div ref={mobileHeaderOverlayRef} className='mobile-header-overlay'>
          <AbroadBrokerNavigationButton id='abroad-broker-mobile-nav-button' />
          {buttonText.map((item, key) => (
            <div className='mobile-header-button-item' key={key}>
              {key === 0 ? (
                <a href={brokerLoginUrl}>
                  <Button className={`btn btn-${getButtonColor(key)}`}>
                    {item.text}
                  </Button>
                </a>
              ) : (
                <Link to={item.props.url}>
                  <Button className={`btn btn-${getButtonColor(key)}`}>
                    {item.text}
                  </Button>
                </Link>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
